import Bindable from '../utensils/bindable';

export default class AjaxVoter {
  constructor(el, data) {
    this.el = el;
    this.data = data ? data : this.el.data();
    this.addListeners();
  }

  dispose() {
    this.removeListeners();
  }

  addListeners() {
    this.el.on('ajax:success', 'a.register-vote-js', this.countVote);
  }

  removeListeners() {
    return this.el.off('ajax:success', 'a.register-vote-js', this.countVote);
  }

  countVote(event, data) {
    const a = $(event.target);
    const parent = a.parent();
    parent.addClass('already-voted');
    const svg = a.find('svg');
    svg.detach().appendTo(parent);
    a.remove();
  }
}

Bindable.register('ajax-voter', AjaxVoter);
